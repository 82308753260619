var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12"},[_c('Toast'),_c('div',{staticClass:"card"},[_c('h5',[_vm._v("Nova Consignatária")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"cnpj"}},[_vm._v("CNPJ")]),_c('InputMask',{class:{
                'p-invalid': _vm.submitted && _vm.v$.consignataria.cnpj.$invalid,
              },attrs:{"id":"cnpj","mask":"99.999.999/9999-99"},model:{value:(_vm.consignataria.cnpj),callback:function ($$v) {_vm.$set(_vm.consignataria, "cnpj", $$v)},expression:"consignataria.cnpj"}}),(_vm.submitted && _vm.v$.consignataria.cnpj.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo CNPJ é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"razao_social"}},[_vm._v("Razão Social")]),_c('InputText',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignataria.razaoSocial.$invalid,
              },attrs:{"id":"razao_social","type":"text"},model:{value:(_vm.consignataria.razaoSocial),callback:function ($$v) {_vm.$set(_vm.consignataria, "razaoSocial", $$v)},expression:"consignataria.razaoSocial"}}),(
                _vm.submitted && _vm.v$.consignataria.razaoSocial.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Razão Social é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"nome_fantasia"}},[_vm._v("Nome Fantasia")]),_c('InputText',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignataria.nomeFantasia.$invalid,
              },attrs:{"id":"nome_fantasia","type":"text"},model:{value:(_vm.consignataria.nomeFantasia),callback:function ($$v) {_vm.$set(_vm.consignataria, "nomeFantasia", $$v)},expression:"consignataria.nomeFantasia"}}),(
                _vm.submitted && _vm.v$.consignataria.nomeFantasia.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Nome Fantasia é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"cep"}},[_vm._v("CEP")]),_c('InputMask',{class:{
                'p-invalid': _vm.submitted && _vm.v$.consignataria.cep.$invalid,
              },attrs:{"id":"cep","type":"text","mask":"99999999"},model:{value:(_vm.consignataria.cep),callback:function ($$v) {_vm.$set(_vm.consignataria, "cep", $$v)},expression:"consignataria.cep"}}),(_vm.submitted && _vm.v$.consignataria.cep.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo CEP é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"logradouro"}},[_vm._v("Logradouro")]),_c('InputText',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignataria.logradouro.$invalid,
              },attrs:{"id":"logradouro","type":"text"},model:{value:(_vm.consignataria.logradouro),callback:function ($$v) {_vm.$set(_vm.consignataria, "logradouro", $$v)},expression:"consignataria.logradouro"}}),(
                _vm.submitted && _vm.v$.consignataria.logradouro.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Logradouro é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"numero"}},[_vm._v("Número")]),_c('InputNumber',{class:{
                'p-invalid': _vm.submitted && _vm.v$.consignataria.numero.$invalid,
              },attrs:{"id":"numero","type":"text"},model:{value:(_vm.consignataria.numero),callback:function ($$v) {_vm.$set(_vm.consignataria, "numero", $$v)},expression:"consignataria.numero"}}),(_vm.submitted && _vm.v$.consignataria.numero.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Número é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{attrs:{"for":"complemento"}},[_vm._v("Complemento")]),_c('InputText',{attrs:{"id":"complemento","type":"text"},model:{value:(_vm.consignataria.complemento),callback:function ($$v) {_vm.$set(_vm.consignataria, "complemento", $$v)},expression:"consignataria.complemento"}})],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"bairro"}},[_vm._v("Bairro")]),_c('InputText',{class:{
                'p-invalid': _vm.submitted && _vm.v$.consignataria.bairro.$invalid,
              },attrs:{"id":"bairro","type":"text"},model:{value:(_vm.consignataria.bairro),callback:function ($$v) {_vm.$set(_vm.consignataria, "bairro", $$v)},expression:"consignataria.bairro"}}),(_vm.submitted && _vm.v$.consignataria.bairro.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Bairro é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"cidade"}},[_vm._v("Cidade")]),_c('InputText',{class:{
                'p-invalid': _vm.submitted && _vm.v$.consignataria.cidade.$invalid,
              },attrs:{"id":"cidade","type":"text"},model:{value:(_vm.consignataria.cidade),callback:function ($$v) {_vm.$set(_vm.consignataria, "cidade", $$v)},expression:"consignataria.cidade"}}),(_vm.submitted && _vm.v$.consignataria.cidade.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Cidade é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"uf"}},[_vm._v("UF")]),_c('Dropdown',{class:{ 'p-invalid': _vm.submitted && _vm.v$.uf.$invalid },attrs:{"id":"uf","options":_vm.ufs,"filter":true,"filterPlaceholder":"Procure pela UF","optionLabel":"name"},model:{value:(_vm.uf),callback:function ($$v) {_vm.uf=$$v},expression:"uf"}}),(_vm.submitted && _vm.v$.uf.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo UF é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"telefone"}},[_vm._v("Telefone")]),_c('InputMask',{class:{
                'p-invalid': _vm.submitted && _vm.v$.consignataria.telefone.$invalid,
              },attrs:{"id":"telefone","mask":"(99)99999-9999","type":"text"},model:{value:(_vm.consignataria.telefone),callback:function ($$v) {_vm.$set(_vm.consignataria, "telefone", $$v)},expression:"consignataria.telefone"}}),(_vm.submitted && _vm.v$.consignataria.telefone.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Telefone é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"email"}},[_vm._v("E-mail")]),_c('InputText',{class:{
                'p-invalid': _vm.submitted && _vm.v$.consignataria.email.$invalid,
              },attrs:{"id":"email","type":"text"},model:{value:(_vm.consignataria.email),callback:function ($$v) {_vm.$set(_vm.consignataria, "email", $$v)},expression:"consignataria.email"}}),(_vm.submitted && _vm.v$.consignataria.email.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo E-mail é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"email_financeiro"}},[_vm._v("E-mail Financeiro")]),_c('InputText',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignataria.emailFinanceiro.$invalid,
              },attrs:{"id":"email_financeiro","type":"text"},model:{value:(_vm.consignataria.emailFinanceiro),callback:function ($$v) {_vm.$set(_vm.consignataria, "emailFinanceiro", $$v)},expression:"consignataria.emailFinanceiro"}}),(
                _vm.submitted &&
                _vm.v$.consignataria.emailFinanceiro.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo E-mail Financeiro é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"data_inicio_contrato"}},[_vm._v("Data de Início do Contrato")]),_c('Calendar',{class:{
                'p-invalid':
                  _vm.submitted &&
                  (_vm.v$.dataInicioContrato.$invalid || _vm.dataInicioInvalida),
              },attrs:{"id":"data_inicio_contrato","showIcon":true,"dateFormat":"dd/mm/yy"},model:{value:(_vm.dataInicioContrato),callback:function ($$v) {_vm.dataInicioContrato=$$v},expression:"dataInicioContrato"}}),(_vm.submitted && _vm.v$.dataInicioContrato.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Data de Início do Contrato é obrigatório. ")]):_vm._e(),(_vm.dataInicioInvalida)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Data de Ínicio do Contrato está incorreto. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"data_fim_contrato"}},[_vm._v("Data de Fim do Contrato")]),_c('Calendar',{class:{
                'p-invalid':
                  _vm.submitted &&
                  (_vm.v$.dataFimContrato.$invalid ||
                    _vm.dataInvalida ||
                    _vm.dataFimInvalida),
              },attrs:{"id":"data_fim_contrato","showIcon":true,"dateFormat":"dd/mm/yy"},model:{value:(_vm.dataFimContrato),callback:function ($$v) {_vm.dataFimContrato=$$v},expression:"dataFimContrato"}}),(_vm.submitted && _vm.v$.dataFimContrato.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Data de Fim do Contrato é obrigatório. ")]):_vm._e(),(_vm.dataInvalida)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Data de Fim do Contrato precisa ser maior ou igual ao campo de Ínicio de Contrato. ")]):_vm._e(),(_vm.dataFimInvalida)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Data de Fim do Contrato está incorreto. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"numero_convenio"}},[_vm._v("Numero do Convênio")]),_c('InputText',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignataria.numeroConvenio.$invalid,
              },attrs:{"id":"numero_convenio","type":"text"},model:{value:(_vm.consignataria.numeroConvenio),callback:function ($$v) {_vm.$set(_vm.consignataria, "numeroConvenio", $$v)},expression:"consignataria.numeroConvenio"}}),(
                _vm.submitted && _vm.v$.consignataria.numeroConvenio.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Número do Convênio é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12"},[_c('label',{staticClass:"required",attrs:{"for":"tipo_cobranca"}},[_vm._v("Tipo Cobrança")]),_c('InputText',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignataria.tipoCobranca.$invalid,
              },attrs:{"id":"tipo_cobranca","type":"text"},model:{value:(_vm.consignataria.tipoCobranca),callback:function ($$v) {_vm.$set(_vm.consignataria, "tipoCobranca", $$v)},expression:"consignataria.tipoCobranca"}}),(
                _vm.submitted && _vm.v$.consignataria.tipoCobranca.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Tipo Cobrança é obrigatório. ")]):_vm._e()],1)]),(_vm.id && _vm.$auth.hasRoleGestao())?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.consignataria.ativo ? 'Ativo' : 'Inativo')+" ")]),_c('div',[_c('InputSwitch',{model:{value:(_vm.consignataria.ativo),callback:function ($$v) {_vm.$set(_vm.consignataria, "ativo", $$v)},expression:"consignataria.ativo"}})],1)]):_vm._e(),_c('Button',{staticClass:"md:col-2 mt-3",attrs:{"type":"submit","label":"Salvar"}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }