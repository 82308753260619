<template>
  <div class="grid">
    <div class="col-12">
      <Toast />
      <div class="card">
        <h5>Nova Consignatária</h5>
        <form @submit.prevent="validate()">
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="cnpj" class="required">CNPJ</label>
              <InputMask
                id="cnpj"
                v-model="consignataria.cnpj"
                mask="99.999.999/9999-99"
                :class="{
                  'p-invalid': submitted && v$.consignataria.cnpj.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.cnpj.required.$invalid"
                class="p-error">
                O campo CNPJ é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="razao_social" class="required">Razão Social</label>
              <InputText
                id="razao_social"
                v-model="consignataria.razaoSocial"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.razaoSocial.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.razaoSocial.required.$invalid
                "
                class="p-error">
                O campo Razão Social é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="nome_fantasia" class="required">Nome Fantasia</label>
              <InputText
                id="nome_fantasia"
                v-model="consignataria.nomeFantasia"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.nomeFantasia.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.nomeFantasia.required.$invalid
                "
                class="p-error">
                O campo Nome Fantasia é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="cep" class="required">CEP</label>
              <InputMask
                id="cep"
                v-model="consignataria.cep"
                type="text"
                mask="99999999"
                :class="{
                  'p-invalid': submitted && v$.consignataria.cep.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.cep.required.$invalid"
                class="p-error">
                O campo CEP é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="logradouro" class="required">Logradouro</label>
              <InputText
                id="logradouro"
                v-model="consignataria.logradouro"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.logradouro.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.logradouro.required.$invalid
                "
                class="p-error">
                O campo Logradouro é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="numero" class="required">Número</label>
              <InputNumber
                id="numero"
                v-model="consignataria.numero"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.consignataria.numero.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.numero.required.$invalid"
                class="p-error">
                O campo Número é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="complemento">Complemento</label>
              <InputText
                id="complemento"
                v-model="consignataria.complemento"
                type="text" />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="bairro" class="required">Bairro</label>
              <InputText
                id="bairro"
                v-model="consignataria.bairro"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.consignataria.bairro.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.bairro.required.$invalid"
                class="p-error">
                O campo Bairro é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="cidade" class="required">Cidade</label>
              <InputText
                id="cidade"
                v-model="consignataria.cidade"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.consignataria.cidade.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.cidade.required.$invalid"
                class="p-error">
                O campo Cidade é obrigatório.
              </div>
            </div>
          </div>

          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="uf" class="required">UF</label>
              <Dropdown
                id="uf"
                v-model="uf"
                :class="{ 'p-invalid': submitted && v$.uf.$invalid }"
                :options="ufs"
                :filter="true"
                filterPlaceholder="Procure pela UF"
                optionLabel="name" />
              <div v-if="submitted && v$.uf.required.$invalid" class="p-error">
                O campo UF é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="telefone" class="required">Telefone</label>
              <InputMask
                id="telefone"
                v-model="consignataria.telefone"
                mask="(99)99999-9999"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.consignataria.telefone.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.telefone.required.$invalid"
                class="p-error">
                O campo Telefone é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="email" class="required">E-mail</label>
              <InputText
                id="email"
                v-model="consignataria.email"
                type="text"
                :class="{
                  'p-invalid': submitted && v$.consignataria.email.$invalid,
                }" />
              <div
                v-if="submitted && v$.consignataria.email.required.$invalid"
                class="p-error">
                O campo E-mail é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="email_financeiro" class="required"
                >E-mail Financeiro</label
              >
              <InputText
                id="email_financeiro"
                v-model="consignataria.emailFinanceiro"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.emailFinanceiro.$invalid,
                }" />
              <div
                v-if="
                  submitted &&
                  v$.consignataria.emailFinanceiro.required.$invalid
                "
                class="p-error">
                O campo E-mail Financeiro é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="data_inicio_contrato" class="required"
                >Data de Início do Contrato</label
              >
              <Calendar
                id="data_inicio_contrato"
                v-model="dataInicioContrato"
                :class="{
                  'p-invalid':
                    submitted &&
                    (v$.dataInicioContrato.$invalid || dataInicioInvalida),
                }"
                :showIcon="true"
                dateFormat="dd/mm/yy" />
              <div
                v-if="submitted && v$.dataInicioContrato.required.$invalid"
                class="p-error">
                O campo Data de Início do Contrato é obrigatório.
              </div>
              <div v-if="dataInicioInvalida" class="p-error">
                O campo Data de Ínicio do Contrato está incorreto.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="data_fim_contrato" class="required"
                >Data de Fim do Contrato</label
              >
              <Calendar
                id="data_fim_contrato"
                v-model="dataFimContrato"
                :class="{
                  'p-invalid':
                    submitted &&
                    (v$.dataFimContrato.$invalid ||
                      dataInvalida ||
                      dataFimInvalida),
                }"
                :showIcon="true"
                dateFormat="dd/mm/yy" />
              <div
                v-if="submitted && v$.dataFimContrato.required.$invalid"
                class="p-error">
                O campo Data de Fim do Contrato é obrigatório.
              </div>
              <div v-if="dataInvalida" class="p-error">
                O campo Data de Fim do Contrato precisa ser maior ou igual ao
                campo de Ínicio de Contrato.
              </div>
              <div v-if="dataFimInvalida" class="p-error">
                O campo Data de Fim do Contrato está incorreto.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="numero_convenio" class="required"
                >Numero do Convênio</label
              >
              <InputText
                id="numero_convenio"
                v-model="consignataria.numeroConvenio"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.numeroConvenio.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.numeroConvenio.required.$invalid
                "
                class="p-error">
                O campo Número do Convênio é obrigatório.
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="tipo_cobranca" class="required">Tipo Cobrança</label>
              <InputText
                id="tipo_cobranca"
                v-model="consignataria.tipoCobranca"
                type="text"
                :class="{
                  'p-invalid':
                    submitted && v$.consignataria.tipoCobranca.$invalid,
                }" />
              <div
                v-if="
                  submitted && v$.consignataria.tipoCobranca.required.$invalid
                "
                class="p-error">
                O campo Tipo Cobrança é obrigatório.
              </div>
            </div>
          </div>

          <div v-if="id && $auth.hasRoleGestao()">
            <div>
              {{ consignataria.ativo ? 'Ativo' : 'Inativo' }}
            </div>
            <div>
              <InputSwitch v-model="consignataria.ativo" />
            </div>
          </div>
          <Button type="submit" label="Salvar" class="md:col-2 mt-3"></Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import moment from 'moment'
import Consignataria from '@/domain/Consignataria.js'
import ConsignatariaService from '@/service/ConsignatariaService.js'

export default {
  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      consignataria: new Consignataria(),
      dataInicioContrato: null,
      dataFimContrato: null,
      dataInvalida: false,
      dataFimInvalida: false,
      dataInicioInvalida: false,
      id: this.$route.params.id,
      submitted: false,
      submitStatus: null,
      ativo: true,
      uf: {},
      ufs: [
        { name: 'AC', code: 'AC' },
        { name: 'AL', code: 'AL' },
        { name: 'AM', code: 'AM' },
        { name: 'AP', code: 'AP' },
        { name: 'BA', code: 'BA' },
        { name: 'CE', code: 'CE' },
        { name: 'DF', code: 'DF' },
        { name: 'ES', code: 'ES' },
        { name: 'GO', code: 'GO' },
        { name: 'MA', code: 'MA' },
        { name: 'MG', code: 'MG' },
        { name: 'MS', code: 'MS' },
        { name: 'MT', code: 'MT' },
        { name: 'PA', code: 'PA' },
        { name: 'PB', code: 'PB' },
        { name: 'PE', code: 'PE' },
        { name: 'PI', code: 'PI' },
        { name: 'PR', code: 'PR' },
        { name: 'RJ', code: 'RJ' },
        { name: 'RN', code: 'RN' },
        { name: 'RO', code: 'RO' },
        { name: 'RR', code: 'RR' },
        { name: 'RS', code: 'RS' },
        { name: 'SC', code: 'SC' },
        { name: 'SE', code: 'SE' },
        { name: 'SP', code: 'SP' },
        { name: 'TO', code: 'TO' },
      ],
    }
  },

  validations() {
    return {
      consignataria: {
        cnpj: { required },
        razaoSocial: { required },
        nomeFantasia: { required },
        cep: { required },
        logradouro: { required },
        numero: { required },
        bairro: { required },
        cidade: { required },
        telefone: { required },
        email: { required },
        emailFinanceiro: { required },
        numeroConvenio: { required },
        tipoCobranca: { required },
      },
      uf: { required },
      dataInicioContrato: { required },
      dataFimContrato: { required },
    }
  },

  created() {
    this.service = new ConsignatariaService(this.$http)
    if (this.id) {
      this.carregarConsignataria()
    }
  },

  methods: {
    carregarConsignataria() {
      this.service.getConsignataria(this.id).then(
        (res) => {
          this.consignataria = res
          this.consignataria.numero = Number(this.consignataria.numero)
          this.obj = {
            name: this.consignataria.uf,
            code: this.consignataria.uf,
          }
          this.uf = this.obj
          this.formatarData()
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    formatarData() {
      if (
        this.consignataria.dataInicioContrato != null ||
        this.consignataria.dataInicioContrato != ''
      ) {
        this.dataInicioContrato = moment(
          this.consignataria.dataInicioContrato,
          'DD-MM-YYYY',
        ).toDate()
      }

      if (
        this.consignataria.dataFimContrato != null ||
        this.consignataria.dataFimContrato != ''
      ) {
        this.dataFimContrato = moment(
          this.consignataria.dataFimContrato,
          'DD-MM-YYYY',
        ).toDate()
      }
    },

    validate() {
      this.submitted = true
      this.v$.consignataria.$touch()
      if (
        (this.validaDataInicio() && this.validaDataFim()) ||
        this.validaDataInicio() ||
        this.validaDataFim()
      ) {
        return
      }

      if (this.dataInicioContratoIsMenorDataFimContrato()) {
        this.exibeToast('data-inicio-maior')
        this.dataInvalida = true
        return
      }
      this.dataInvalida = false
      if (
        this.v$.consignataria.$invalid ||
        this.v$.dataInicioContrato.$invalid ||
        this.v$.uf.$invalid ||
        this.v$.dataFimContrato.$invalid
      ) {
        return
      } else {
        this.salvar()
      }
    },

    salvar() {
      this.atribuirDados()
      this.service.saveConsignataria(this.consignataria).then(
        () => {
          if (this.id) {
            this.exibeToast('update')
          } else {
            this.exibeToast('save')
          }
          this.$router.push({
            name: 'consignatarias',
          })
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    atribuirDados() {
      if (this.consignataria.id == null && this.$auth.hasRoleGestaoEntidade()) {
        this.consignataria.ativo = false
      }
      this.consignataria.cnpj = this.consignataria.cnpj.replace(/[^0-9]/g, '')
      this.consignataria.uf = this.uf.name
      this.consignataria.dataInicioContrato = this.dataInicioContrato
      this.consignataria.dataFimContrato = this.dataFimContrato
    },

    dataInicioContratoIsMenorDataFimContrato() {
      return moment(this.dataInicioContrato).isAfter(this.dataFimContrato)
    },

    validaDataInicio() {
      if (!moment(this.dataInicioContrato).isValid()) {
        this.dataInicioInvalida = true
        return true
      } else {
        this.dataInicioInvalida = false
        return false
      }
    },

    validaDataFim() {
      if (!moment(this.dataFimContrato).isValid()) {
        this.dataFimInvalida = true
        return true
      } else {
        this.dataFimInvalida = false
        return false
      }
    },

    exibeToast(tipo, msg) {
      if (tipo === 'save') {
        this.$toast.add({
          severity: 'success',
          summary: 'Consignatária salva com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      } else if (tipo === 'update') {
        this.$toast.add({
          severity: 'success',
          summary: 'Consignatária Atualizada com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'data-inicio-maior') {
        this.$toast.add({
          severity: 'error',
          summary:
            'A Data de Fim do Contrato precisa ser maior ou igual a Data de Ínicio do Contrato! Digite corretamente',
          life: 10000,
        })
      }
    },

    moment: function () {
      return moment()
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
